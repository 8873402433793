<template>
    <div class="page">
        <div class="query-form p_b1">
            <div class="m_b1">
                <span class="fontSize14">消费时间</span>
                <el-date-picker class="m_l1 m_r2" size="small"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                v-model="consumeTime"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期" style="width: 30%">
                </el-date-picker>
                <span class="fontSize14">发票申请时间</span>
                <el-date-picker class="m_l1 m_r1" size="small"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                v-model="applyForTime"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期" style="width: 30%">
                </el-date-picker>
            </div>
            <div>
                <el-select class="m_r1" clearable v-model="searchForm.headingType" placeholder="抬头类型" size="small"
                           style="width: 18%">
                    <el-option
                            v-for="item in $dictUtils.getDictList('headingType')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
                <el-input class="m_r1" size="small" v-model="searchForm.keyWords" maxlength="50"
                          placeholder="公司名称/公司税号/邮箱" clearable style="width: 30%"></el-input>
                <el-button type="primary" @click="queryData(1)" size="small"
                           icon="el-icon-search">查询
                </el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </div>
        </div>
        <div class="bg-white">
            <div class="flex_b_c">
                <el-radio-group v-model="searchForm.applicationStatus" size="small" @input="queryData(1)">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">未推送</el-radio-button>
                    <el-radio-button label="1">已推送</el-radio-button>
                </el-radio-group>
                <el-button type="primary" v-if="hasPermission('invoice:make')"
                           :disabled="dataListSelections.length <= 0" @click="makeInvoice()" size="small">开票
                </el-button>
            </div>
            <el-table :data="list"
                      v-loading="loading"
                      @selection-change="selectionChangeHandle"
                      size="small" ref="table"
                      height="calc(100vh - 350px)" class="table">
                <el-table-column type="selection" :selectable="checkSelectable" width="50" fixed></el-table-column>
                <el-table-column prop="headingType" label="抬头类型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("headingType",scope.row.headingType,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="corporateName" label="公司名称" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="companyTaxNumber" label="公司税号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="userMailbox" label="邮箱" show-overflow-tooltip></el-table-column>
                <el-table-column prop="userPhone" label="手机号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createTime" label="申请时间" width="140" show-overflow-tooltip></el-table-column>
                <el-table-column prop="billMoney" label="发票总额（元）" width="140" show-overflow-tooltip></el-table-column>
                <el-table-column prop="applicationStatus" label="开票状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("applicationStatus",scope.row.applicationStatus,'')}}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" width="140" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('invoice:make') && scope.row.applicationStatus == 0"
                                   icon="el-icon-s-check" type="text" size="mini" @click="makeInvoice([scope.row.id])">
                            开票
                        </el-button>
                        <el-button v-if="hasPermission('invoice:view')" icon="el-icon-view" type="text"
                                   size="mini" @click="view(scope.row)">查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="text_center"
                           :current-page="searchForm.current"
                           :page-size="searchForm.size"
                           :page-sizes="[10, 20, 50, 100]"
                           :total="total"
                           @size-change="sizeChangeHandle"
                           @current-change="currentChangeHandle"
                           background
                           layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--查看-->
        <InvoiceDetails ref="invoiceDetails"></InvoiceDetails>
    </div>
</template>

<script>
    import InvoiceDetails from './invoiceDetails'

    export default {
        components: {InvoiceDetails},
        data() {
            return {
                consumeTime: [],
                applyForTime: [],
                searchForm: {
                    applicationStatus: '',
                    applyForStartTime: '',
                    applyForEndTime: '',
                    consumeStartTime: '',
                    consumeEndTime: '',
                    headingType: '',
                    keyWords: '',
                    current: 1,
                    size: 10,
                },
                // 订单列表
                list: [],
                loading: false,
                total: 0,
                dataListSelections: [],
            }
        },
        mounted() {
            this.queryData(1)
        },
        methods: {
            queryData(current) {
                let param = JSON.parse(JSON.stringify(this.searchForm))
                param.consumeStartTime = this.consumeTime ? this.consumeTime[0] : ''
                param.consumeEndTime = this.consumeTime ? this.consumeTime[1] : ''
                param.applyForStartTime = this.applyForTime ? this.applyForTime[0] : ''
                param.applyForEndTime = this.applyForTime ? this.applyForTime[1] : ''
                if (current) {
                    param.current = current
                    this.searchForm.current = current
                } else {
                    param.current = this.searchForm.current
                }
                this.loading = true
                this.$axios(this.api.website.sysGetWebsiteBill, param, 'post').then((res) => {
                    if (res.status) {
                        this.list = res.data.records
                        this.total = parseInt(res.data.total)
                        this.$nextTick(() => {
                            this.$refs.table.doLayout();
                        })
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            checkSelectable(row) {
                return row.applicationStatus == '0'
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 重置
            resetSearch() {
                this.consumeTime = []
                this.applyForTime = []
                this.searchForm = {
                    applicationStatus: '',
                    applyForStartTime: '',
                    applyForEndTime: '',
                    consumeStartTime: '',
                    consumeEndTime: '',
                    headingType: '',
                    keyWords: '',
                    current: 1,
                    size: 10,
                }
                this.queryData(1)
            },
            // 查看
            view(row) {
                this.$refs.invoiceDetails.init(row)
            },
            // 开票
            makeInvoice(id) {
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                })
                const h = this.$createElement;
                this.$msgbox({
                    title: '提示',
                    message: h('p', null, [
                        h('p', null, '确定该订单已开电子发票？'),
                        h('p', {style: 'font-size: 12px'}, '确定后该订单（电子发票）申请状态将变更为：已开票')
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    this.$axios(this.api.website.updateApplicationStatus, ids, 'post').then((res) => {
                        if (res.status) {
                            this.$message.success('开票成功')
                            this.queryData();
                        } else {
                            this.$message.error('开票失败')
                        }
                    })
                });
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val;
                this.searchForm.current = 1;
                this.queryData()
            }
            ,

            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val;
                this.queryData()
            }
            ,
        }
    }
</script>

<style scoped>

</style>
